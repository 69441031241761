<template>
  <div>
    <el-dialog title="指定出卷"
               :visible.sync="dialogVisible"
               v-dialogDrag
               :close-on-click-modal="false"
               :append-to-body="true"
               :width="width"
               :before-close="handleClose">
      <el-form :model="form"
               :rules="rules"
               ref="formRef"
               label-width="80px">
        <el-row :gutter="10"
                style="display:flex;flex-wrap:wrap">
          <el-col :span="8">
            <el-form-item label="试卷名称">
              <el-input clearable
                        placeholder="请输入"
                        style="width:100%"
                        v-model="form.paper_name" />
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item label="指定年级"
                          prop="given_garde">
              <el-select v-model="form.given_garde"
                         style="width:100%"
                         @change="gradeChange"
                         placeholder="选择">
                <el-option v-for="item in gradeList"
                           :key="item.grade_id"
                           :label="item.grade_name"
                           :value="item.grade_id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="试卷科目"
                          prop="subjectId">
              <el-select v-model="form.subjectId"
                         style="width:100%"
                         @change="getSourceList"
                         placeholder="选择">
                <el-option v-for="item in subjectList"
                           :key="item.subject_id"
                           :label="item.subject_name"
                           :value="item.subject_id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item label="试卷地区"
                          prop="district_id">
              <el-select v-model="form.district_id"
                         style="width:100%"
                         filterable
                         @change="getSourceList"
                         placeholder="选择">
                <el-option v-for="item in districtList"
                           :key="item.paper_district_id"
                           :label="item.paper_district_name"
                           :value="item.paper_district_id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item label="试卷来源"
                          prop="source_id">
              <el-select v-model="form.source_id"
                         style="width:100%"
                         @change="getYearList"
                         filterable
                         placeholder="选择">
                <el-option v-for="item in sourceList"
                           :key="item.source_id"
                           :label="item.source_name"
                           :value="item.source_id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item label="试卷年份"
                          prop="question_year">
              <el-select v-model="form.question_year"
                         style="width:100%"
                         placeholder="选择">
                <el-option v-for="item in year"
                           :key="item.question_year"
                           :label="item.question_year"
                           :value="item.question_year">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item label="试卷学期">
              <el-select v-model="form.semester"
                         style="width:100%"
                         clearable
                         placeholder="选择">
                <el-option v-for="item in semesterList"
                           :key="item.id"
                           :label="item.label"
                           :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item label="试卷类型">
              <el-select v-model="form.edu_paper_type"
                         style="width:100%"
                         clearable
                         placeholder="选择">
                <el-option v-for="item in paperTypeList"
                           :key="item.paper_type_id"
                           :label="item.paper_type_name"
                           :value="item.paper_type_id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item label="试卷时长">
              <el-input clearable
                        placeholder="请输入"
                        style="width:100%"
                        v-model="form.paper_duration" />
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item label="开始时间">
              <el-date-picker v-model="form.paper_time"
                              style="width:100%"
                              type="datetime"
                              @focus="$forbid"
                              value-format="yyyy-MM-dd HH:mm:ss"
                              placeholder="试卷开始时间" />
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item label="截止时间">
              <el-date-picker v-model="form.end_time"
                              style="width:100%"
                              @focus="$forbid"
                              type="datetime"
                              default-time="23:59:59"
                              value-format="yyyy-MM-dd HH:mm:ss"
                              placeholder="试卷开始时间" />
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item label="试卷可见">
              <el-select v-model="form.permissions"
                         style="width:100%"
                         placeholder="选择">
                <el-option v-for="item in permissionsList"
                           :key="item.id"
                           :label="item.label"
                           :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>

        </el-row>
        <p>
          说明：
        </p>
        <p>
          1.试卷可以定时开始训练，也可以不选时间，这样学生任何时间都可以训练；
        </p>
        <p>
          2.组的试卷必须提交才能导出或提供给学生做题。
        </p>
        <!-- <p>
          3.指定年级和指定班级二选一
        </p> -->

        <!-- <p>
          （注：试卷类型添加“模考统考”，由上到下依次为“单元检测”、“月考联考”、“期中考试”、“期
          末考试”、“模考统考”）
        </p> -->
        <el-row :gutter="10"
                style="text-align:center;margin-top:20px">
          <el-col :span="24">
            <el-button type="primary"
                       @click="toInfo">提交</el-button>
          </el-col>
        </el-row>
      </el-form>

    </el-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      form: {},
      width: '900px',
      showList: [],
      dialogVisible: false,
      testingList: [],
      subjectList: [],
      paperTypeList: [],
      gradeList: [],
      classList: [],
      sourceList: [],
      districtList: [],
      semesterList: [
        { label: '上学期', value: 0 },
        { label: '下学期', value: 1 },
        { label: '未指定', value: 2 },
      ],
      rules: {
        paper_name: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        paper_time: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        subjectId: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        district_id: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        source_id: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        question_year: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        semester: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        given_garde: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        given_class: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        edu_paper_type: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
      },
      year: [],
      semesterList: [
        { id: 0, label: '上学期' },
        { id: 1, label: '下学期' },
        { id: 2, label: '未指定' },
      ],
      permissionsList: [
        { id: 1, label: '默认' },
        { id: 2, label: '私人' },
      ]
    }
  },
  mounted () {
    if (document.body.clientWidth < 1200) {
      this.width = '700px'
    }
  },
  methods: {
    getGradeAndClass () {
      this.$http({
        url: '/api/v1/public/subject_get_grade_and_class',
        method: 'get',
        params: {
          subject_id: this.form.subjectId
        }
      }).then(res => {
        console.log('resaaaa', res)
        this.classList = res.data.class_data
        this.gradeList = res.data.grade_data
      })
    },
    toInfo () {
      this.$refs.formRef.validate(val => {
        if (!val) return
        let form = JSON.parse(JSON.stringify(this.form))
        form.subject_id = form.subjectId
        delete form.subjectId

        this.$http({
          url: '/api/v1/combination/whole_create',
          method: 'post',
          data: form
        }).then(res => {
          this.$notify({
            title: '提示',
            message: '操作成功!',
            type: 'success'
          });
          this.$router.push('/testPapercomposition/autonomously/addDetails?edu_paper_id=' + res.data.edu_paper_id)
        })
      })
    },
    getDirList () {
      this.$http({
        url: '/api/v1/public/select_district',
        method: 'get',
      }).then(res => {
        this.districtList = Object.values(res.data)
      })
    },
    getYearList () {
      this.form.year = ''
      this.year = []
      if (this.form.source_id == '') return
      this.$http({
        url: '/api/v1/public/select_source_year',
        method: 'get',
        params: {
          source_id: this.form.source_id
        }
      }).then(res => {
        this.year = res.data
      })
    },

    subjectChage () {

    },
    gradeChange () {
      this.form.subjectId = ''
      this.form.district_id = ''
      this.form.source_id = ''
      this.form.question_year = ''
      this.year = []
      this.sourceList = []
      this.getSubjectList()
    },
    getClass () {
      this.$http({
        url: '/api/v1/special/tc_lead_class',
        method: 'get',
      }).then(res => {
        this.classList = res.data
      })
    },
    getSourceList () {
      this.form.source_id = ''
      this.form.question_year = ''
      this.year = []
      this.sourceList = []
      if (this.form.subjectId == '' || this.form.district_id == '') {
        return
      }
      this.$http({
        url: 'api/v1/public/source_for_ds',
        method: 'get',
        params: {
          district_id: this.form.district_id,
          subject_id: this.form.subjectId,
          grade_id: this.form.given_garde
        }
      }).then(res => {
        this.sourceList = res.data
      })
    },
    getList () {
      this.getGradeList()
      this.getDirList()
      this.getPaperTypeList()
    },
    getSubjectList () {
      if (!this.form.given_garde && !this.form.given_class) {
        return
      }
      this.$http({
        url: '/api/v1/public/class_grade_subject',
        method: 'get',
        params: {
          grade_id: this.form.given_garde,
          // class_id: this.form.given_class
        }
      }).then(res => {
        this.subjectList = res.data
      })
    },
    getGradeList () {
      this.$http({
        url: '/api/v1/school/select_grade',
        method: 'get'
      }).then(res => {
        this.gradeList = res.data.list
      })
    },
    getPaperTypeList () {
      this.$http({
        url: '/api/v1/public/get_all_paper_type',
        method: 'GET',
      }).then(res => {
        this.paperTypeList = res.data
        if (this.paperTypeList.length == 1) {
          this.form.edu_paper_type = this.paperTypeList[0].paper_type_id
        }
      })
    },
    handleClose () {
      this.$refs.formRef.resetFields()
      this.dialogVisible = false
      this.form.source_id = 0
    }
  }
}
</script>

<style lang="scss" scoped>
.warp {
  width: 98%;
  margin: 0 auto;
  border-top: 1px solid #ddd;
  font-size: 14px;
  div {
    margin-left: 15px;
  }
}

::v-deep .el-dialog__header {
  font-size: 20px;
  font-weight: bold;
  color: black;
  text-align: center;
}

::v-deep .el-dialog__headerbtn .el-dialog__close {
  color: white;
  background: #cccccc;
  border-radius: 50%;
  padding: 4px;
}
</style>